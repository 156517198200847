import React, { useState, useEffect } from 'react';
import ReMap from "../parts/ReMap";
import useHttp from "../parts/useHttp";
import CenterServiceInfo from "./CenterServiceInfo";
import { Helmet } from 'react-helmet-async';
import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";

const CenterService = ({ centerId, serviceName }) => {

	const [showMap, setMapView] = useState(false);
	const openMapView = (e) => {
		e.preventDefault();
		setMapView(true);
	};
	const closeMapView = (e) => {
		e.preventDefault();
		setMapView(false);
	};
	useEffect(() => {
		if (showMap) {
			const mapElement = document.getElementById('center-map');
			const navbar = document.querySelector('.navbar.fixed-top');
			const navbarHeight = navbar ? navbar.offsetHeight : 0;
			if (mapElement) {
				const elementPosition = mapElement.getBoundingClientRect().top + window.scrollY;
				window.scrollTo({
					top: elementPosition - navbarHeight,
					behavior: 'smooth',
				});
				mapElement.classList.add('map-showed');
			}
		}
	}, [showMap]);

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers/${centerId}`;
  let pageUrl = window.location.href;

  const {data, loading, error} = useHttp(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  const centerName = Capitalize(data?.data.center_name.split('-')[0].trim()); // no province
  const provinceName = Capitalize(data?.data.geo_data.province_name);
  const regionName = Capitalize(data?.data.geo_data.region_name);
  const localityName = Capitalize(data?.data.geo_data.locality_name);

  let description = "";
  let title = `SaluteGo - "${Capitalize(serviceName)}" ${Capitalize(localityName)} presso ${FormatCenterName(centerName)}`;

  if (centerName && provinceName && serviceName) {
    if (provinceName === localityName) {
      description = `"${Capitalize(serviceName)}" a ${FormatCenterName(centerName)}, Centro Medico a ${Capitalize(localityName)} in ${regionName}`;
    } else {
      description = `"${Capitalize(serviceName)}" a ${FormatCenterName(centerName)}, Centro Medico a ${Capitalize(localityName)} in provincia di ${Capitalize(provinceName)}`;
    }
  }

  return(
    <>
      <Helmet>
      <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={pageUrl} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:image" content="https://salutego.it/img/cart-trova-centro-medico.jpg" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div id="col-center-map" className="col-lg-4">
        <div className="container container-top container-h-100 slgo-mobile-w-94 p-0 mt-0 mb-0 mt-md-5 mt-md-5">
          <div className="row g-5">
            <div className="col-md-12 m-0">
              <div className="row g-0">
              <div className="col-12 order-2 order-md-1">
				<ReMap 
                  markersLatLong={[{latitude: data?.data.geo_data.latitude, longitude: data?.data.geo_data.longitude}]} 
                  showOnSmallDevices={showMap}
                  hideOnPhones={true}
                />
			  </div>
				<div className="col-md-12 px-4 py-3 mb-2 mb-md-5 order-1 order-md-2">
					<CenterServiceInfo data={data} />
					{!showMap ? (
						<a
						href="#"
						className="slgo-btn-show-center-map slgo-text mt-3 mb-0 d-block d-md-none"
						onClick={openMapView}
						>
						<b><u>Visualizza mappa</u></b>
						</a>
					) : (
						<a
						href="#"
						className="slgo-btn-close-center-map slgo-text mt-3 mb-0 d-block d-md-none"
						onClick={closeMapView}
						>
						<b><u>Chiudi mappa</u></b>
						</a>
					)}
				</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default CenterService;